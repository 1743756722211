.TraitsSection {
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fd87bf;
  padding-bottom: 20px;
}

.TraitSection_Title {
  color: white;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 60px;
  animation: float 2s ease-in-out infinite;
  margin-top: 10px;
}

.TraitsSection_Grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.TraitsSection_Purchase {
  min-height: 500px;
  width: 275px;
  background: white;
  border-radius: 138px;
  margin-right: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.TraitsSection_Purchase p {
  text-align: center;
}

.TraitsSection_Purchase img {
  position: absolute;
  top: 0;
  width: 275px;
}

.TraitsSection_Purchase button {
  background: #ee9298;
  border-radius: 20px;
  color: white;
  padding: 10px;
  font-family: "Montserrat";
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 1s;
  margin-bottom: 60px;
}

.TraitsSection_Traits p {
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: "Montserrat";
  font-weight: 100;
  color: white;
  font-size: 1.2rem;
}

@media only screen and (max-width: 940px) {
  .TraitSection_Title {
    margin-top: 10px;
    font-size: 3.4rem;
  }
  .TraitsSection_Purchase {
    margin-right: 0;
  }

  .TraitsSection_Traits {
    text-align: center;
  }
  .TraitsSection_Grid {
    flex-direction: column;
  }
}
