.MissionSection {
  min-height: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: url("../../assets/images/boobs-storybg.png");
  background-color: #ffde72;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.MissionSection_Title {
  color: black;
  font-size: 5rem;
  font-weight: bold;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 60px;
  animation: float 2s ease-in-out infinite;
}

.MissionSection_Info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  font-size: 1.2rem;
  font-weight: 300;
  margin-right: 20px;
  max-width: 80%;
}

.MissionSection_Footer {
  text-align: right;
  font-weight: bold;
  font-size: 2rem;
  color: #f81894;
}

.MissionSection_Footer a {
  text-decoration: none;
  font-size: 1.5rem;
  color: black;
}

.MissionSection_Elements {
  display: flex;
  justify-content: center;
}

.MissionSection_Grid {
  display: flex;
}
.MissionSection_Grid img {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 600px;
}

@media only screen and (max-width: 940px) {
  .MissionSection_Title {
    font-size: 4rem;
  }
  .MissionSection_Grid {
    justify-content: center;
  }
  .MissionSection_Elements {
    flex-direction: column;
    align-items: center;
  }

  .MissionSection_Info {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 40px;
    padding: 10px;
  }

  .MissionSection_Title {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 0;
    text-align: center;
  }

  .MissionSection_Grid img {
    max-width: 75%;
  }
}
