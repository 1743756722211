.TeamSection {
  background: #ee9298;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.TeamSection_Title {
  color: white;
  font-size: 5rem;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 0px;
  animation: float 2s ease-in-out infinite;
}

.TeamSection_Grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TeamSection_Entry {
  margin: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TeamSection_Entry p {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  color: #f9d253;
}

.TeamSection_Entry a {
  margin-top: 10px;
}

.TeamSection_Entry p:nth-child(2) {
  font-weight: bold;
  font-size: 1.6rem;
  color: white;
}

.TeamSection_PF {
  width: 100px;
  height: 100px;
}

.TeamSection_PF img {
  width: 100%;
}

@media only screen and (max-width: 940px) {
  .TeamSection_Title {
    font-size: 4rem;
  }
  .TeamSection_Grid {
    flex-direction: column;
  }
}
