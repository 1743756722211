.RoadmapSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
}

.RoadmapSection_Title {
  color: black;
  font-size: 5rem;
  font-weight: bold;
  margin-top: 60px;
  animation: float 2s ease-in-out infinite;
}

.RoadmapSection_Entry {
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  position: relative;
}

.RoadmapSection_Entry img {
  position: absolute;
  max-width: 200px;
  top: 60%;
  left: 15%;
}

.RoadmapSection_Entry p:first-child {
  padding: 40px;
  width: 50px;
  height: 50px;
  background: #fd87bf;
  color: black;
  font-family: "Montserrat";
  font-weight: bold;
  border-radius: 10px;
  font-size: 2rem;
  box-shadow: 0px 0px 40px #fd87bf;
  animation: blink 2s ease-in-out infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RoadmapSection_Entry p:nth-child(2) {
  margin-left: 20px;
  padding: 40px;
  max-width: 30%;
  font-weight: 100;
}

@media only screen and (max-width: 940px) {
  .RoadmapSection_Title {
    font-size: 4rem;
  }
  .RoadmapSection_Entry img {
    display: none;
  }

  .RoadmapSection_Entry {
    padding-left: 40px;
  }

  .RoadmapSection_Entry p:nth-child(2) {
    max-width: 90%;
  }
}
