.SocialsSection {
  text-align: center;
  padding-bottom: 60px;
}

.SocialsSection_Icons {
  margin-top: 10px;
  margin-bottom: 10px;
}

.SocialsSection_Icons img {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.SocialsSection_Icons img:hover {
  opacity: 0.75;
}

.SocialsSection a {
  color: #e88e97;
  text-decoration: none;
  text-decoration: underline;
}

.SocialsSection_Rights {
  color: #e88e97;
}

.SocialsSection_Contract {
  color: #e88e97;
}
