.StorySection {
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.StorySection_Title {
  color: #fd87bf;
  font-size: 5rem;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 0px;
  margin-left: 120px;
  animation: float 2s ease-in-out infinite;
}

.StorySection_Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fd87bf;
  font-size: 1.2rem;
  font-family: "Montserrat";
  font-weight: 100;
  margin-right: 20px;
}

.StorySection_Elements {
  display: flex;
  padding-left: 120px;
  padding-right: 120px;
}

.StorySection_Grid {
  display: flex;
}
.StorySection_Grid img {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 600px;
}

@media only screen and (max-width: 940px) {
  .StorySection_Elements {
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
  }

  .StorySection_Title {
    margin-left: 0;
    text-align: center;
    font-size: 4rem;
  }

  .StorySection_Info {
    margin-right: 0;
    text-align: center;
  }

  .StorySection_Grid {
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .StorySection_Grid div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .StorySection_Grid img {
    max-width: 45%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}
