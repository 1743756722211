.WalletInfoHeader {
  height: 40px;
  width: 100%;
  background: #ff9d9d;
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}

.WalletInfoHeader p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  font-family: "Montserrat";
  font-weight: bold;
}

.WalletInfoHeader_Connection:hover {
  cursor: pointer;
  opacity: 0.85;
}

.WalletInfoHeader_Connection {
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  font-family: "Montserrat";
  font-weight: bold;
}
