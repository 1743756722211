.Hero {
  width: 100%;
  /* position: relative; */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -5;
  background: url("../../assets/images/boobs-hero.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Hero_Icon {
  position: absolute;
  right: 30%;
  top: 18%;
  height: 20rem;
  z-index: 10;
}

.Hero_MainImage {
  max-width: 60%;
}

.Hero_Subtitle {
  position: relative;
  color: black;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.9rem;
  max-width: 25%;
  z-index: 10;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 25rem;
}

.Hero_Notice {
  font-family: Montserrat;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 40px;
  color: white;
  position: relative;
  letter-spacing: 0.05em;
}

a {
  z-index: 10;
}

.Hero_Button {
  background: #ee9298;
  border-radius: 20px;
  color: white;
  padding: 10px;
  font-family: "Montserrat";
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 1s;
}

.Hero_Button:hover {
  opacity: 0.65;
}

.Hero_ActionButton {
  background: white;
  border: none;
  color: black;
  font-family: "Montserrat", cursive;
  padding: 16px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 1.6rem;
  font-weight: bold;
  z-index: 100;
  border-radius: 5px;
  margin-top: 20px;
  transition: 1s;
  cursor: pointer;
}

.Hero_MobileImage {
  display: none;
  margin: 0 auto;
}

.Hero_ActionButton:hover {
  transform: translateY(5px);
  opacity: 0.75;
}

@media only screen and (max-width: 600px) {
  .Hero_MobileImage {
    display: block;
  }
  .Hero_Icon {
    position: relative;
    left: unset;
    right: unset;
    top: unset;
  }
  .Hero {
    /* padding-top: 80px; */
    margin-top: 0;
    margin-bottom: 0;
    background: white;
  }

  .Hero_Subtitle {
    margin-top: 20px;
  }
  .Hero {
    min-height: 400px;
  }

  .Hero_Title {
    margin-top: 5rem;
    font-size: 2.5rem;
  }

  .Hero_Subtitle {
    max-width: 90%;
  }

  .Hero_Description {
    max-width: 75%;
  }

  .Hero_RedCapsule {
    width: 100px;
  }
  .Hero_PurpleCapsule {
    width: 200px;
  }
}

@media screen and (orientation: landscape) and (max-width: 840px) {
  .Hero_Icon {
    position: relative;
    left: unset;
    right: unset;
    top: unset;
  }
  .Hero {
    background: white;
  }

  .Hero_Subtitle {
    margin-top: 20px;
  }

  .Hero {
    min-height: 400px;
    margin-bottom: 80px;
  }

  .Hero_Title {
    margin-top: 5rem;
    font-size: 2.5rem;
  }

  .Hero_Subtitle {
    max-width: 90%;
  }

  .Hero_Description {
    max-width: 75%;
  }
}
