.FAQSection {
  min-height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.FAQSection_Title {
  color: black;
  font-size: 5rem;
  font-weight: bold;
  margin-top: 60px;
  animation: float 2s ease-in-out infinite;
}

.FAQSection_Entry {
  background: #ff9d9d;
  padding: 20px;
  border-radius: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}

.FAQSection_EntryTitle {
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
}

.FAQSection_Entry .FAQSection_EntryDescription {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear, height 1s;
  height: 0;
}

.FAQSection_EntryOpen .FAQSection_EntryDescription {
  opacity: 1;
  margin-top: 20px;
  visibility: visible;
  opacity: 1;
  height: unset;
}

.FAQSection_Entry:first-child {
  margin-top: 0 !important;
}

@media only screen and (max-width: 940px) {
  .FAQSection_Title {
    font-size: 4rem;
  }
}
